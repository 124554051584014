import React, { useState } from "react";
import { FaUserCircle, FaFileImage, FaMobile } from "react-icons/fa";

const MyProfile2 = () => {
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [profileUpdateData, setProfileUpdateData] = useState({
    fullName: "",
    email: "",
    mobile: "",
  });
  const [errors, setErrors] = useState({});

  const handleProfilePhotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfilePhoto(URL.createObjectURL(file));
    }
  };

  const handleProfilePhotoClick = () => {
    document.getElementById("profile-photo-input").click();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileUpdateData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const validationErrors = {};
    if (!profileUpdateData.fullName.trim()) {
      validationErrors.fullName = "Full name is required";
    }
    if (!profileUpdateData.email.trim()) {
      validationErrors.email = "Email is required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(profileUpdateData.email)
    ) {
      validationErrors.email = "Invalid email format";
    }
    if (!profileUpdateData.mobile.trim()) {
      validationErrors.mobile = "Mobile number is required";
    } else if (!/^\d{10}$/i.test(profileUpdateData.mobile)) {
      validationErrors.mobile = "Invalid mobile number";
    }

    if (Object.keys(validationErrors).length === 0) {
      console.log("Profile Update Data:", profileUpdateData);

      setProfileUpdateData({
        fullName: "",
        email: "",
        mobile: "",
      });
      setErrors({});
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <div className="flex bg-gray-800 p-4 justify-end">
      <div className="bg-gray-800 p-8 rounded-md shadow-md w-full ">
        <div className="flex flex-col items-center mb-6">
          <div onClick={handleProfilePhotoClick} className="cursor-pointer">
            {profilePhoto ? (
              <img
                src={profilePhoto}
                alt="Profile Preview"
                className="h-36 w-36 rounded-full mb-2"
                onClick={() => setProfilePhoto(null)}
              />
            ) : (
              <FaUserCircle className="h-36 w-36 text-gray-500 mb-2" />
            )}
          </div>
          <input
            id="profile-photo-input"
            name="profile-photo"
            type="file"
            className="hidden"
            accept="image/*"
            onChange={handleProfilePhotoChange}
          />
        </div>

        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 gap-y-6">
            <div>
              <label
                htmlFor="fullName"
                className="block text-sm font-medium text-gray-200 mb-2"
              >
                Full Name
              </label>
              <input
                type="text"
                name="fullName"
                id="fullName"
                autoComplete="name"
                className={`block w-full rounded-md border-gray-700 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm h-10 bg-gray-700 text-white p-4 ${
                  errors.fullName ? "border-red-500" : ""
                }`}
                placeholder="John Doe"
                value={profileUpdateData.fullName}
                onChange={handleInputChange}
              />
              {errors.fullName && (
                <p className="text-xs text-red-500">{errors.fullName}</p>
              )}
            </div>

            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-200 mb-2"
              >
                Email
              </label>
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                className={`block w-full rounded-md border-gray-700 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm h-10 bg-gray-700 text-white p-4 ${
                  errors.email ? "border-red-500" : ""
                }`}
                value={profileUpdateData.email}
                onChange={handleInputChange}
              />
              {errors.email && (
                <p className="text-xs text-red-500">{errors.email}</p>
              )}
            </div>

            <div>
              <label
                htmlFor="mobile"
                className="block text-sm font-medium text-gray-200 mb-2"
              >
                Mobile
              </label>
              <div className="flex items-center mt-1">
                <FaMobile className="text-gray-500 text-lg mr-3" />
                <input
                  id="mobile"
                  name="mobile"
                  type="tel"
                  autoComplete="tel"
                  className={`block w-full rounded-md border-gray-700 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm h-10 bg-gray-700 text-white p-4 ${
                    errors.mobile ? "border-red-500" : ""
                  }`}
                  placeholder="1234567890"
                  value={profileUpdateData.mobile}
                  onChange={handleInputChange}
                />
              </div>
              {errors.mobile && (
                <p className="text-xs text-red-500">{errors.mobile}</p>
              )}
            </div>
          </div>

          <div className="flex items-center justify-end mt-6">
            <button
              type="button"
              className="text-sm font-semibold text-gray-200 mr-4 hover:underline"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="rounded-md bg-indigo-500 px-10 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default MyProfile2;
