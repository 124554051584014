const questionsData = [
  {
    questionId: 258,
    questionText: "Question 1",
    customerId: 70,
    answerOption: ["A", "B", "C", "D"],
    answerType: "multiple-selection",
    createdAt: "2023-10-13T05:32:04.000Z",
    updatedAt: "2023-10-13T05:32:04.000Z",
    deletedAt: null,
  },
  {
    questionId: 259,
    questionText: "Question 2",
    customerId: 70,
    answerOption: ["A", "B", "C", "D"],
    answerType: "single-selection",
    createdAt: "2023-10-13T05:32:04.000Z",
    updatedAt: "2023-10-13T05:32:04.000Z",
    deletedAt: null,
  },
  {
    questionId: 260,
    questionText: "Question 3",
    customerId: 70,
    answerOption: null,
    answerType: "text",
    createdAt: "2023-10-13T05:32:04.000Z",
    updatedAt: "2023-10-13T05:32:04.000Z",
    deletedAt: null,
  },
  {
    questionId: 261,
    questionText: "Question 4",
    customerId: 70,
    answerOption: null,
    answerType: "numeric",
    createdAt: "2023-10-13T05:32:04.000Z",
    updatedAt: "2023-10-13T05:32:04.000Z",
    deletedAt: null,
  },
  {
    questionId: 262,
    questionText: "Question 5",
    customerId: 70,
    answerOption: ["A", "B", "C", "D"],
    answerType: "multiple-selection",
    createdAt: "2023-10-13T05:32:04.000Z",
    updatedAt: "2023-10-13T05:32:04.000Z",
    deletedAt: null,
  },
  {
    questionId: 263,
    questionText: "Question 6",
    customerId: 70,
    answerOption: null,
    answerType: "boolean",
    createdAt: "2023-10-13T05:32:04.000Z",
    updatedAt: "2023-10-13T05:32:04.000Z",
    deletedAt: null,
  },
  {
    questionId: 264,
    questionText: "Question 7",
    customerId: 70,
    answerOption: null,
    answerType: "date",
    createdAt: "2023-10-13T05:32:04.000Z",
    updatedAt: "2023-10-13T05:32:04.000Z",
    deletedAt: null,
  },

  {
    questionId: 265,
    questionText: "Question 9",
    customerId: 70,
    answerOption: ["A", "B", "C", "D"],
    answerType: "single-selection",
    createdAt: "2023-10-13T05:32:04.000Z",
    updatedAt: "2023-10-13T05:32:04.000Z",
    deletedAt: null,
  },
  {
    questionId: 266,
    questionText: "Upload your photo",
    customerId: 70,
    answerOption: null,
    answerType: "image-upload",
    createdAt: "2023-10-13T05:32:04.000Z",
    updatedAt: "2023-10-13T05:32:04.000Z",
    deletedAt: null,
  },
  {
    questionId: 267,
    questionText: "which fruit ?",
    customerId: 70,
    answerOption: ["papaya", "grapes", "apple", "mango"],
    answerType: "multiple-selection",
    createdAt: "2023-10-13T05:32:04.000Z",
    updatedAt: "2023-10-13T05:32:04.000Z",
    deletedAt: null,
  },
  {
    questionId: 268,
    questionText: "Upload your documents",
    customerId: 70,
    answerOption: null,
    answerType: "document-upload",
    createdAt: "2023-10-13T05:32:04.000Z",
    updatedAt: "2023-10-13T05:32:04.000Z",
    deletedAt: null,
  },
];

export default questionsData;
