import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Video1 from "./assest/Video1.mp4";
import Video2 from "./assest/Video2.mp4";
import Video3 from "./assest/Video3.mp4";
import Video4 from "./assest/Video4.mp4";
import Video5 from "./assest/Video5.mp4";
import Video6 from "./assest/Video6.mp4";
import Video7 from "./assest/Video7.mp4";
import Video8 from "./assest/Video8.mp4";
import Video9 from "./assest/Video9.mp4";
import Video10 from "./assest/Video10.mp4";
import Video11 from "./assest/Video11.mp4";
import Video12 from "./assest/Video12.mp4";
import Video13 from "./assest/Video13.mp4";
import Video14 from "./assest/Video14.mp4";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import "./videoBanner.scss";
const videoData = [
  { id: 1, videoSrc: Video1, text: "Nature  " },
  { id: 2, videoSrc: Video2, text: "Nature  " },
  { id: 3, videoSrc: Video4, text: "Nature  " },
  { id: 5, videoSrc: Video5, text: "Nature  " },
  { id: 6, videoSrc: Video6, text: "Nature  " },
  { id: 7, videoSrc: Video7, text: "Nature  " },
  { id: 8, videoSrc: Video8, text: "Nature  " },
  { id: 9, videoSrc: Video9, text: "Nature  " },
  { id: 10, videoSrc: Video10, text: "Nature  " },
  { id: 11, videoSrc: Video12, text: "Nature  " },
  { id: 13, videoSrc: Video13, text: "Nature  " },
  { id: 14, videoSrc: Video14, text: "Nature  " },
];

const VideoContainer = ({ videoSrc, text }) => {
  const handleTogglePlay = (event) => {
    console.log("handleTogglePlay == > ", event.target);
    // const video = videoRef.current;

    if (event.target.paused) {
      event.target.play();
      // video.play();
    } else {
      event.target.pause();
    }
  };
  const handleKeyDown = (event) => {
    // Check if the pressed key is the space key (keyCode 32)
    if (event.keyCode === 32) {
      handleTogglePlay();
    }
  };
  return (
    <div
      className="relative w-3/12  h-full overflow-hidden border rounded-lg"
      style={{ minWidth: "320px" }}
    >
      <video
        width="640"
        height="360"
        controls={false}
        autoPlay
        playsInline
        onClick={handleTogglePlay}
        onKeyDown={handleKeyDown}
        className="z-20"
      >
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="absolute text-[3rem] text-white z-40 bottom-9 left-5">
        {text}
      </div>
      <div
        class="absolute opacity-100 bg-[#0000004d]  flex items-center justify-center "
        style={{
          width: "48px",
          height: "48px",
          borderRadius: "50%",
          top: "calc(50% - 24px)",
          left: "calc(50% - 24px)",
        }}
        // onClick={handleTogglePlay}
      >
        <svg
          width="32"
          height="32"
          viewBox="0 0 24 24"
          fill="#FFFFFF"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21.633 10.72L8.136 2.47a1.5 1.5 0 00-2.283 1.28v16.498a1.5 1.5 0 002.283 1.28l13.497-8.249a1.502 1.502 0 000-2.56z"
            fill="#FFFFFF"
          ></path>
        </svg>
      </div>
    </div>
  );
};

const VideoBanner1 = () => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 10000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 10000,
  };

  const sliderRef = useRef(null);
  const scroll = (scrollOffset) => {
    if (sliderRef.current) {
      sliderRef.current.scrollLeft += scrollOffset;
    }
  };

  return (
    <div className="relative">
      <div className="grid  " style={{ gridTemplateRows: "1fr 1fr 7fr" }}>
        <div
          className="text-[1.85rem]  pt-4 px-4 text-[#664ccf]"
          style={{ gridRow: "1", gridColumn: "1 / span 1", fontWeight: "500" }}
        >
        
          {/* Content for the first row */}
          {/* You can control the width by adjusting the content or using inline styles */}
          Thoughtful Creation
        </div>
        <div
          className="text-[2.5rem] pb-4 px-4"
          style={{ gridRow: "2", gridColumn: "1 / span 1" }}
        >
          {/* Content for the first row */}
          {/* You can control the width by adjusting the content or using inline styles */}
          Of our <span style={{ fontFamily: "Satisfy" }}>finest</span>{" "}
          experiences
        </div>
        <div
          className="  videoBanner m-4 p-4 border relative  overflow-x-hidden "
          style={{ gridRow: "3", gridColumn: "1 / span 1" }}
        >
          <button
            onClick={() => scroll(-250)}
            ref={sliderRef}
            className="absolute top-1/2 md:left-[-0.5rem]  scroll-m-0  ml-2 transform -translate-y-1/2 rounded-full bg-gradient-to-r from-indigo-400 to-cyan-400 p-2 text-xl focus:outline-none z-40"
          >
            <FaChevronLeft className="text-white" />
          </button>

          <div
            className=" videoBanner relative overflow-auto flex gap-4 scroll-m-0 "
            ref={sliderRef}
          >
            {videoData.map((obj) => {
              return (
                <VideoContainer videoSrc={obj?.videoSrc} text={obj?.text} />
              );
            })}
          </div>
          <button
            onClick={() => scroll(250)}
            className="absolute top-1/2 right-[-0.4rem] transform -translate-y-1/2 rounded-full bg-gradient-to-r from-indigo-400 to-cyan-400 p-2 text-xl focus:outline-none z-40"
          >
            <FaChevronRight className="text-white" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default VideoBanner1;
