import React, { useEffect, useState } from "react";

import SideBanner1 from "../../components/home-services/side-banner/SideBanner1";
import SideBanner2 from "../../components/home-services/side-banner/SideBanner2";
import SideBanner3 from "../../components/home-services/side-banner/SideBanner3";
import SideBanner4 from "../../components/home-services/side-banner/SideBanner4";

import ReactSideBanner1 from "!!raw-loader!../../components/home-services/side-banner/SideBanner1";
import ReactSideBanner2 from "!!raw-loader!../../components/home-services/side-banner/SideBanner2";
import ReactSideBanner3 from "!!raw-loader!../../components/home-services/side-banner/SideBanner3";
import ReactSideBanner4 from "!!raw-loader!../../components/home-services/side-banner/SideBanner4";

import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import PreviewCodeSection from "../../subcomponent/PreviewCode/PreviceCodeSection";

import pretty from "pretty";
const ComponentData = [
  {
    id: 1,
    component: SideBanner1,
    rawString_React: ReactSideBanner1,
  },
  {
    id: 2,
    component: SideBanner2,
    rawString_React: ReactSideBanner2,
  },
  {
    id: 3,
    component: SideBanner3,
    rawString_React: ReactSideBanner3,
  },
  {
    id: 4,
    component: SideBanner4,
    rawString_React: ReactSideBanner4,
  },
];

const SideBanner = () => {
  return (
    <>
      {ComponentData.map((obj) => {
        return (
          <PreviewCodeSection
            key={obj?.id}
            Component={obj?.component}
            rawString_React={obj?.rawString_React}
          />
        );
      })}
    </>
  );
};

export default SideBanner;
