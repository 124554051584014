import React, { useEffect, useState } from "react";
import Toast1 from "../../components/home-services/toast/Toast1";
import Toast2 from "../../components/home-services/toast/Toast2";
import Toast3 from "../../components/home-services/toast/Toast3";
import Toast4 from "../../components/home-services/toast/Toast4";
import Toast5 from "../../components/home-services/toast/Toast5";
import Toast6 from "../../components/home-services/toast/Toast6";

import ReactToast1 from "!!raw-loader!../../components/home-services/toast/Toast1";
import ReactToast2 from "!!raw-loader!../../components/home-services/toast/Toast2";
import ReactToast3 from "!!raw-loader!../../components/home-services/toast/Toast3";
import ReactToast4 from "!!raw-loader!../../components/home-services/toast/Toast4";
import ReactToast5 from "!!raw-loader!../../components/home-services/toast/Toast5";
import ReactToast6 from "!!raw-loader!../../components/home-services/toast/Toast6";

import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import PreviewCodeSection from "../../subcomponent/PreviewCode/PreviceCodeSection";

import pretty from "pretty";

const ComponentData = [
  {
    id: 1,
    component: Toast1,
    rawString_React: ReactToast1,
  },
  {
    id: 2,
    component: Toast2,
    rawString_React: ReactToast2,
  },
  {
    id: 3,
    component: Toast3,
    rawString_React: ReactToast3,
  },
  {
    id: 4,
    component: Toast4,
    rawString_React: ReactToast4,
  },
  {
    id: 5,
    component: Toast5,
    rawString_React: ReactToast5,
  },
  {
    id: 6,
    component: Toast6,
    rawString_React: ReactToast6,
  },
];

const Toasts = () => {
  return (
    <>
      {ComponentData.map((obj) => {
        return (
          <PreviewCodeSection
            key={obj?.id}
            Component={obj?.component}
            rawString_React={obj?.rawString_React}
          />
        );
      })}
    </>
  );
};

export default Toasts;
