import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HeroBanner3 = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
  };

  return (
    <div className="relative">
      {/* Form Section */}
      <div className="absolute top-0 left-0 right-0 bg-gray-700 bg-opacity-20 p-8 rounded-2xl w-7/12 mt-20 ml-10 z-50 ">
        <h2 className="text-4xl font-bold mt-1 text-white">
          Your Personal Assistant
        </h2>
        <p className="text-xl mt-4 text-white">
          Simple and affordable way to get things done.
        </p>
        <form className="flex items-center mt-8 gap-4">
          <select
            id="countries"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-3/12 pr-2.5  py-2"
          >
            <option value="CA">Canada</option>
            <option value="FR">France</option>
            <option value="DE">Germany</option>
          </select>
          <div className="relative w-full">
            <input
              type="text"
              id="voice-search"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 "
              placeholder="Find your service here e.g. AC, Car, Facial"
              required
            />
            <button
              type="button"
              className="absolute inset-y-0 right-0 flex items-center pr-3"
            >
              <svg
                className="w-4 h-4 text-gray-500  hover:text-gray-900 "
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 16 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 7v3a5.006 5.006 0 0 1-5 5H6a5.006 5.006 0 0 1-5-5V7m7 9v3m-3 0h6M7 1h2a3 3 0 0 1 3 3v5a3 3 0 0 1-3 3H7a3 3 0 0 1-3-3V4a3 3 0 0 1 3-3Z"
                />
              </svg>
            </button>
          </div>
        </form>
      </div>

      {/* Carousel Section */}
      <Slider {...settings} className="mt-14 z-20">
        <div className="h-96 relative">
          {/* First Slide Content */}
          <img
            src="https://mdbcdn.b-cdn.net/img/Photos/Slides/img%20(19).webp"
            alt="Motorbike Smoke"
          />
          <div className="absolute inset-x-[15%] bottom-5 py-5 text-center text-black"></div>
        </div>
        <div className="h-96 relative">
          {/* Second Slide Content */}
          <img
            src="https://mdbcdn.b-cdn.net/img/Photos/Slides/img%20(35).webp"
            alt="Mountaintop"
          />
          <div className="absolute inset-x-[15%] bottom-5 py-5 text-center text-black"></div>
        </div>
        <div className="h-96 relative">
          {/* Third Slide Content */}
          <img
            src="https://mdbcdn.b-cdn.net/img/Photos/Slides/img%20(40).webp"
            alt="Woman Reading a Book"
          />
          <div className="absolute inset-x-[15%] bottom-5 py-5 text-center text-black"></div>
        </div>
      </Slider>
    </div>
  );
};

export default HeroBanner3;
