import React, { useEffect, useRef } from "react";

import lottie from "lottie-web";
import animationData from "../../social/assets/loading4.json";

const FullScreenLoader4 = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    const animation = lottie.loadAnimation({
      container: containerRef.current,
      animationData: animationData,
      loop: true,
      autoplay: true,
    });

    return () => {
      animation.destroy();
    };
  }, []);

  return (
    <div className="flex items-center justify-center h-screen bg-gray-400 bg-opacity-20">
      <div className="flex flex-col items-center justify-center h-screen w-screen z-50">
        <div ref={containerRef} />
        <div className="flex space-x-2 justify-center items-center bg-transparent mt-6">
          <span className="sr-only">Loading...</span>
          <div className="h-6 w-6 bg-blue-300 rounded-full animate-bounce [animation-delay:-0.3s]"></div>
          <div className="h-6 w-6 bg-green-300 rounded-full animate-bounce [animation-delay:-0.15s]"></div>
          <div className="h-6 w-6 bg-red-300 rounded-full animate-bounce [animation-delay:-0.3s]"></div>
          <div className="h-6 w-6 bg-yellow-300 rounded-full animate-bounce [animation-delay:-0.15s]"></div>
          <div className="h-6 w-6 bg-purple-300 rounded-full animate-bounce [animation-delay:-0.3s]"></div>
          <div className="h-6 w-6 bg-orange-300 rounded-full animate-bounce [animation-delay:-0.15s]"></div>
          <div className="h-6 w-6 bg-pink-300 rounded-full animate-bounce"></div>
        </div>
      </div>
    </div>
  );
};

export default FullScreenLoader4;
