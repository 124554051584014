import React from "react";
import {
  FaBars,
  FaUser,
  FaMapMarker,
  FaClipboard,
  FaBell,
  FaLanguage,
  FaShareAlt,
  FaMoneyBillAlt,
} from "react-icons/fa";
import MyProfile3 from "../my-profile/MyProfile3";

const SideBanner1 = () => {
  return (
    <div className="flex flex-col h-screen bg-gray-100 overflow-hidden">
      <button
        data-drawer-target="logo-sidebar"
        data-drawer-toggle="logo-sidebar"
        aria-controls="logo-sidebar"
        type="button"
        className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 "
      >
        <span className="sr-only">Open sidebar</span>
        <FaBars className="w-6 h-6" />
      </button>
      <div
        id="logo-sidebar"
        className=" absolute h-screen overflow-y-auto w-64 bg-gray-50 "
        aria-label="Sidebar"
      >
        <div className="h-full px-3 py-10">
          <a href="/" className="flex items-center ps-2.5 mb-5">
            <img
              src="https://oyelabs.com/wp-content/uploads/2023/01/Group-80-1.svg"
              className="ml-3 w-40"
              alt="conpany logo "
            />
          </a>
          <ul className="space-y-2 font-medium py-10">
            <li>
              <a
                href="#"
                className="flex items-center p-2 text-gray-800 rounded-lg"
              >
                <FaUser className="w-5 h-5 text-gray-500 transition duration-75" />
                <span className="ms-3">My Profile</span>
              </a>
            </li>
            <li>
              <a
                href="#"
                className="flex items-center p-2 text-gray-800 rounded-lg"
              >
                <FaMapMarker className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75" />
                <span className="flex-1 ms-3 whitespace-nowrap">Address</span>
              </a>
            </li>
            <li>
              <a
                href="#"
                className="flex items-center p-2 text-gray-800 rounded-lg"
              >
                <FaClipboard className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75" />
                <span className="flex-1 ms-3 whitespace-nowrap">
                  My Bookings
                </span>
              </a>
            </li>
            <li>
              <a
                href="#"
                className="flex items-center p-2 text-gray-800 rounded-lg"
              >
                <FaBell className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75" />
                <span className="flex-1 ms-3 whitespace-nowrap">
                  Notifications
                </span>
              </a>
            </li>
            <li>
              <a
                href="#"
                className="flex items-center p-2 text-gray-800 rounded-lg"
              >
                <FaLanguage className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75" />
                <span className="flex-1 ms-3 whitespace-nowrap">
                  Change Language
                </span>
              </a>
            </li>
            <li>
              <a
                href="#"
                className="flex items-center p-2 text-gray-800 rounded-lg"
              >
                <FaShareAlt className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75" />
                <span className="flex-1 ms-3 whitespace-nowrap">
                  Refer and Earn
                </span>
              </a>
            </li>
            <li>
              <a
                href="#"
                className="flex items-center p-2 text-gray-800 rounded-lg"
              >
                <FaMoneyBillAlt className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75" />
                <span className="flex-1 ms-3 whitespace-nowrap">
                  Refer Credit
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="p-4 sm:ml-64 pt-28 bg-white">
        <MyProfile3 className="mt-16" />
      </div>
      <span className="ml-96 mt-20"> Bottom section</span>
    </div>
  );
};

export default SideBanner1;
