import React, { useEffect, useState } from "react";
import CategorySlider from "../../components/home-services/category-slider/CategorySlider";
import ReactCategorySlider from "!!raw-loader!../../components/home-services/category-slider/CategorySlider";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import PreviewCodeSection from "../../subcomponent/PreviewCode/PreviceCodeSection";

import pretty from "pretty";

const ComponentData = [
  {
    id: 1,
    component: CategorySlider,
    rawString_React: ReactCategorySlider,
  },
];

const Sliders = () => {
  return (
    <>
      {ComponentData.map((obj) => {
        return (
          <PreviewCodeSection
            key={obj?.id}
            Component={obj?.component}
            rawString_React={obj?.rawString_React}
          />
        );
      })}
    </>
  );
};

export default Sliders;
