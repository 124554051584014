import React, { useState } from "react";
import { FaUserCircle, FaFileImage, FaMobile } from "react-icons/fa";

const MyProfile3 = () => {
  const [photo, setPhoto] = useState(null);
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    mobile: "",
  });
  const [errors, setErrors] = useState({});

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setPhoto(URL.createObjectURL(file));
    }
  };

  const handlePhotoClick = () => {
    document.getElementById("photo-input").click();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const validationErrors = {};
    if (!formData.fullName.trim()) {
      validationErrors.fullName = "Full name is required";
    }
    if (!formData.email.trim()) {
      validationErrors.email = "Email is required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.email)
    ) {
      validationErrors.email = "Invalid email format";
    }
    if (!formData.mobile.trim()) {
      validationErrors.mobile = "Mobile number is required";
    } else if (!/^\d{10}$/i.test(formData.mobile)) {
      validationErrors.mobile = "Invalid mobile number";
    }

    if (Object.keys(validationErrors).length === 0) {
      console.log("Form Data:", formData);

      setFormData({
        fullName: "",
        email: "",
        mobile: "",
      });
      setErrors({});
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <div className="flex bg-white p-4 justify-start">
      <div className="bg-white p-8 rounded-md shadow-md w-full flex items-center">
        <div onClick={handlePhotoClick} className="cursor-pointer">
          {photo ? (
            <img
              src={photo}
              alt="Preview"
              className="h-44 w-44 rounded-full mb-2"
              onClick={() => setPhoto(null)}
            />
          ) : (
            <FaUserCircle className="h-44 w-44 text-gray-300 mb-2" />
          )}
        </div>
        <input
          id="photo-input"
          name="photo"
          type="file"
          className="hidden"
          accept="image/*"
          onChange={handlePhotoChange}
        />
        <form onSubmit={handleSubmit} className="ml-8 flex-grow">
          <div className="grid grid-cols-1 gap-y-6">
            <div>
              <label
                htmlFor="fullName"
                className="block text-sm font-medium text-gray-900 mb-2"
              >
                Full Name
              </label>
              <input
                type="text"
                name="fullName"
                id="fullName"
                autoComplete="name"
                className={`block w-full rounded-md border-gray-100 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm h-10 bg-gray-50 p-4 ${
                  errors.fullName ? "border-red-500" : ""
                }`}
                placeholder="John Doe"
                value={formData.fullName}
                onChange={handleInputChange}
              />
              {errors.fullName && (
                <p className="text-xs text-red-500">{errors.fullName}</p>
              )}
            </div>

            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-900 mb-2"
              >
                Email
              </label>
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                className={`block w-full rounded-md border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm h-10 bg-gray-50 p-4 ${
                  errors.email ? "border-red-500" : ""
                }`}
                value={formData.email}
                onChange={handleInputChange}
              />
              {errors.email && (
                <p className="text-xs text-red-500">{errors.email}</p>
              )}
            </div>

            <div>
              <label
                htmlFor="mobile"
                className="block text-sm font-medium text-gray-900 mb-2"
              >
                Mobile
              </label>
              <div className="flex items-center mt-1">
                <FaMobile className="text-gray-500 text-lg mr-3" />
                <input
                  id="mobile"
                  name="mobile"
                  type="tel"
                  autoComplete="tel"
                  className={`block w-full rounded-md border-gray-100 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm h-10 bg-gray-50 p-4 ${
                    errors.mobile ? "border-red-500" : ""
                  }`}
                  placeholder="1234567890"
                  value={formData.mobile}
                  onChange={handleInputChange}
                />
              </div>
              {errors.mobile && (
                <p className="text-xs text-red-500">{errors.mobile}</p>
              )}
            </div>
          </div>

          <div className="flex items-center justify-end mt-6">
            <button
              type="button"
              className="text-sm font-semibold text-gray-900 mr-4 hover:underline"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="rounded-md bg-indigo-600 px-10 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default MyProfile3;
