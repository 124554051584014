import React, { useEffect, useState } from "react";

import FullScreenLoader from "../../components/home-services/full-screen-loader/FullScreenLoader";
import FullScreenLoader2 from "../../components/home-services/full-screen-loader/FullScreenLoader2";
import FullScreenLoader3 from "../../components/home-services/full-screen-loader/FullScreenLoader3";
import FullScreenLoader4 from "../../components/home-services/full-screen-loader/FullScreenLoader4";
import FullScreenLoader5 from "../../components/home-services/full-screen-loader/FullScreenLoader5";
import FullScreenLoader6 from "../../components/home-services/full-screen-loader/FullScreenLoader6";
import FullScreenLoader7 from "../../components/home-services/full-screen-loader/FullScreenLoader7";

import ReactFullScreenLoader from "!!raw-loader!../../components/home-services/full-screen-loader/FullScreenLoader";
import ReactFullScreenLoader2 from "!!raw-loader!../../components/home-services/full-screen-loader/FullScreenLoader2";
import ReactFullScreenLoader3 from "!!raw-loader!../../components/home-services/full-screen-loader/FullScreenLoader3";
import ReactFullScreenLoader4 from "!!raw-loader!../../components/home-services/full-screen-loader/FullScreenLoader4";
import ReactFullScreenLoader5 from "!!raw-loader!../../components/home-services/full-screen-loader/FullScreenLoader5";
import ReactFullScreenLoader6 from "!!raw-loader!../../components/home-services/full-screen-loader/FullScreenLoader6";
import ReactFullScreenLoader7 from "!!raw-loader!../../components/home-services/full-screen-loader/FullScreenLoader7";

import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import PreviewCodeSection from "../../subcomponent/PreviewCode/PreviceCodeSection";

import pretty from "pretty";

const ComponentData = [
  {
    id: 1,
    component: FullScreenLoader,
    rawString_React: ReactFullScreenLoader,
  },
  {
    id: 2,
    component: FullScreenLoader2,
    rawString_React: ReactFullScreenLoader2,
  },
  {
    id: 3,
    component: FullScreenLoader3,
    rawString_React: ReactFullScreenLoader3,
  },
  {
    id: 4,
    component: FullScreenLoader4,
    rawString_React: ReactFullScreenLoader4,
  },
  {
    id: 5,
    component: FullScreenLoader5,
    rawString_React: ReactFullScreenLoader5,
  },
  {
    id: 6,
    component: FullScreenLoader6,
    rawString_React: ReactFullScreenLoader6,
  },
  {
    id: 7,
    component: FullScreenLoader7,
    rawString_React: ReactFullScreenLoader7,
  },
];

const ScreenLoaders = () => {
  return (
    <>
      {ComponentData.map((obj) => {
        return (
          <PreviewCodeSection
            key={obj?.id}
            Component={obj?.component}
            rawString_React={obj?.rawString_React}
          />
        );
      })}
    </>
  );
};

export default ScreenLoaders;
