import React from "react";
import logo from "../../../src/componentsLogo.png";
const Section = () => {
  return (
    <div class="relative mx-auto w-full max-w-container space-y-20 px-4 pt-20 sm:px-6 lg:px-8 pb-20">
      <section id="Elements">
        <h2 class="text-xl font-bold text-slate-900">Home service component</h2>
        <div class="mt-10 divide-y divide-slate-100 border-t border-slate-100 lg:mt-12">
          <section
            id="product-marketing-sections"
            class="grid scroll-mt-12 grid-cols-1 gap-x-8 gap-y-10 py-10 lg:py-12 xl:grid-cols-4"
          >
            <h3 class="text-base font-semibold text-slate-900">
              Listed components
            </h3>
            <ul class="col-span-3 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-2 sm:gap-y-10 md:grid-cols-3 xl:gap-x-8">
              <li>
                <div class="group relative before:absolute before:-inset-2.5 before:rounded-[20px] before:bg-gray-50 before:opacity-0 hover:before:opacity-100">
                  <div class="relative aspect-[2/1] overflow-hidden rounded-lg bg-gray-100 ring-1 ring-gray-900/10">
                    <img
                      src={logo}
                      alt=""
                      class="absolute inset-0 h-full w-full"
                    />
                  </div>
                  <h4 class="mt-4 text-sm font-medium text-slate-900 group-hover:text-indigo-600">
                    <a href="/components/social/sections/fly-button">
                      <span class="absolute -inset-2.5 z-10"></span>
                      <span class="relative">Fly Components</span>
                    </a>
                  </h4>
                  <p class="relative mt-1.5 text-xs font-medium text-slate-500">
                    8 components
                  </p>
                </div>
              </li>
              <li>
                <div class="group relative before:absolute before:-inset-2.5 before:rounded-[20px] before:bg-gray-50 before:opacity-0 hover:before:opacity-100">
                  <div class="relative aspect-[2/1] overflow-hidden rounded-lg bg-gray-100 ring-1 ring-gray-900/10">
                    <img
                      src={logo}
                      alt=""
                      class="absolute inset-0 h-full w-full"
                    />
                  </div>
                  <h4 class="mt-4 text-sm font-medium text-slate-900 group-hover:text-indigo-600">
                    <a href="/components/social/sections/Questionaire">
                      <span class="absolute -inset-2.5 z-10"></span>
                      <span class="relative">Questionaire</span>
                    </a>
                  </h4>
                  <p class="relative mt-1.5 text-xs font-medium text-slate-500">
                    8 components
                  </p>
                </div>
              </li>
              <li>
                <div class="group relative before:absolute before:-inset-2.5 before:rounded-[20px] before:bg-gray-50 before:opacity-0 hover:before:opacity-100">
                  <div class="relative aspect-[2/1] overflow-hidden rounded-lg bg-gray-100 ring-1 ring-gray-900/10">
                    <img
                      src={logo}
                      alt=""
                      class="absolute inset-0 h-full w-full"
                    />
                  </div>
                  <h4 class="mt-4 text-sm font-medium text-slate-900 group-hover:text-indigo-600">
                    <a href="/components/social/sections/cards">
                      <span class="absolute -inset-2.5 z-10"></span>
                      <span class="relative">Cards</span>
                    </a>
                  </h4>
                  <p class="relative mt-1.5 text-xs font-medium text-slate-500">
                    8 components
                  </p>
                </div>
              </li>
              <li>
                <div class="group relative before:absolute before:-inset-2.5 before:rounded-[20px] before:bg-gray-50 before:opacity-0 hover:before:opacity-100">
                  <div class="relative aspect-[2/1] overflow-hidden rounded-lg bg-gray-100 ring-1 ring-gray-900/10">
                    <img
                      src={logo}
                      alt=""
                      class="absolute inset-0 h-full w-full"
                    />
                  </div>
                  <h4 class="mt-4 text-sm font-medium text-slate-900 group-hover:text-indigo-600">
                    <a href="/components/home-services/add-address">
                      <span class="absolute -inset-2.5 z-10"></span>
                      <span class="relative">Add Address</span>
                    </a>
                  </h4>
                  <p class="relative mt-1.5 text-xs font-medium text-slate-500">
                    components
                  </p>
                </div>
              </li>
              <li>
                <div class="group relative before:absolute before:-inset-2.5 before:rounded-[20px] before:bg-gray-50 before:opacity-0 hover:before:opacity-100">
                  <div class="relative aspect-[2/1] overflow-hidden rounded-lg bg-gray-100 ring-1 ring-gray-900/10">
                    <img
                      src={logo}
                      alt=""
                      class="absolute inset-0 h-full w-full"
                    />
                  </div>
                  <h4 class="mt-4 text-sm font-medium text-slate-900 group-hover:text-indigo-600">
                    <a href="/components/home-services/carts">
                      <span class="absolute -inset-2.5 z-10"></span>
                      <span class="relative">Carts</span>
                    </a>
                  </h4>
                  <p class="relative mt-1.5 text-xs font-medium text-slate-500">
                    components
                  </p>
                </div>
              </li>{" "}
              <li>
                <div className="group relative before:absolute before:-inset-2.5 before:rounded-[20px] before:bg-gray-50 before:opacity-0 hover:before:opacity-100">
                  <div className="relative aspect-[2/1] overflow-hidden rounded-lg bg-gray-100 ring-1 ring-gray-900/10">
                    <img
                      src={logo}
                      alt=""
                      className="absolute inset-0 h-full w-full"
                    />
                  </div>
                  <h4 className="mt-4 text-sm font-medium text-slate-900 group-hover:text-indigo-600">
                    <a href="/components/home-services/footer">
                      <span className="absolute -inset-2.5 z-10"></span>
                      <span className="relative">Footers</span>
                    </a>
                  </h4>
                  <p className="relative mt-1.5 text-xs font-medium text-slate-500">
                    components
                  </p>
                </div>
              </li>
              <li>
                <div className="group relative before:absolute before:-inset-2.5 before:rounded-[20px] before:bg-gray-50 before:opacity-0 hover:before:opacity-100">
                  <div className="relative aspect-[2/1] overflow-hidden rounded-lg bg-gray-100 ring-1 ring-gray-900/10">
                    <img
                      src={logo}
                      alt=""
                      className="absolute inset-0 h-full w-full"
                    />
                  </div>
                  <h4 className="mt-4 text-sm font-medium text-slate-900 group-hover:text-indigo-600">
                    <a href="/components/home-services/nav">
                      <span className="absolute -inset-2.5 z-10"></span>
                      <span className="relative">Navbars</span>
                    </a>
                  </h4>
                  <p className="relative mt-1.5 text-xs font-medium text-slate-500">
                    components
                  </p>
                </div>
              </li>
              <li>
                <div className="group relative before:absolute before:-inset-2.5 before:rounded-[20px] before:bg-gray-50 before:opacity-0 hover:before:opacity-100">
                  <div className="relative aspect-[2/1] overflow-hidden rounded-lg bg-gray-100 ring-1 ring-gray-900/10">
                    <img
                      src={logo}
                      alt=""
                      className="absolute inset-0 h-full w-full"
                    />
                  </div>
                  <h4 className="mt-4 text-sm font-medium text-slate-900 group-hover:text-indigo-600">
                    <a href="/components/home-services/toast">
                      <span className="absolute -inset-2.5 z-10"></span>
                      <span className="relative">Toasts</span>
                    </a>
                  </h4>
                  <p className="relative mt-1.5 text-xs font-medium text-slate-500">
                    components
                  </p>
                </div>
              </li>
              <li>
                <div className="group relative before:absolute before:-inset-2.5 before:rounded-[20px] before:bg-gray-50 before:opacity-0 hover:before:opacity-100">
                  <div className="relative aspect-[2/1] overflow-hidden rounded-lg bg-gray-100 ring-1 ring-gray-900/10">
                    <img
                      src={logo}
                      alt=""
                      className="absolute inset-0 h-full w-full"
                    />
                  </div>
                  <h4 className="mt-4 text-sm font-medium text-slate-900 group-hover:text-indigo-600">
                    <a href="/components/home-services/my-profile">
                      <span className="absolute -inset-2.5 z-10"></span>
                      <span className="relative">My Profiles</span>
                    </a>
                  </h4>
                  <p className="relative mt-1.5 text-xs font-medium text-slate-500">
                    components
                  </p>
                </div>
              </li>
              <li>
                <div className="group relative before:absolute before:-inset-2.5 before:rounded-[20px] before:bg-gray-50 before:opacity-0 hover:before:opacity-100">
                  <div className="relative aspect-[2/1] overflow-hidden rounded-lg bg-gray-100 ring-1 ring-gray-900/10">
                    <img
                      src={logo}
                      alt=""
                      className="absolute inset-0 h-full w-full"
                    />
                  </div>
                  <h4 className="mt-4 text-sm font-medium text-slate-900 group-hover:text-indigo-600">
                    <a href="/components/home-services/side-banner">
                      <span className="absolute -inset-2.5 z-10"></span>
                      <span className="relative">Side Banners</span>
                    </a>
                  </h4>
                  <p className="relative mt-1.5 text-xs font-medium text-slate-500">
                    components
                  </p>
                </div>
              </li>
              <li>
                <div className="group relative before:absolute before:-inset-2.5 before:rounded-[20px] before:bg-gray-50 before:opacity-0 hover:before:opacity-100">
                  <div className="relative aspect-[2/1] overflow-hidden rounded-lg bg-gray-100 ring-1 ring-gray-900/10">
                    <img
                      src={logo}
                      alt=""
                      className="absolute inset-0 h-full w-full"
                    />
                  </div>
                  <h4 className="mt-4 text-sm font-medium text-slate-900 group-hover:text-indigo-600">
                    <a href="/components/home-services/hero-banner">
                      <span className="absolute -inset-2.5 z-10"></span>
                      <span className="relative">Heros Banners</span>
                    </a>
                  </h4>
                  <p className="relative mt-1.5 text-xs font-medium text-slate-500">
                    components
                  </p>
                </div>
              </li>
              <li>
                <div className="group relative before:absolute before:-inset-2.5 before:rounded-[20px] before:bg-gray-50 before:opacity-0 hover:before:opacity-100">
                  <div className="relative aspect-[2/1] overflow-hidden rounded-lg bg-gray-100 ring-1 ring-gray-900/10">
                    <img
                      src={logo}
                      alt=""
                      className="absolute inset-0 h-full w-full"
                    />
                  </div>
                  <h4 className="mt-4 text-sm font-medium text-slate-900 group-hover:text-indigo-600">
                    <a href="/components/video-banners/video-banners">
                      <span className="absolute -inset-2.5 z-10"></span>
                      <span className="relative">Video Banners</span>
                    </a>
                  </h4>
                  <p className="relative mt-1.5 text-xs font-medium text-slate-500">
                    components
                  </p>
                </div>
              </li>
              <li>
                <div className="group relative before:absolute before:-inset-2.5 before:rounded-[20px] before:bg-gray-50 before:opacity-0 hover:before:opacity-100">
                  <div className="relative aspect-[2/1] overflow-hidden rounded-lg bg-gray-100 ring-1 ring-gray-900/10">
                    <img
                      src={logo}
                      alt=""
                      className="absolute inset-0 h-full w-full"
                    />
                  </div>
                  <h4 className="mt-4 text-sm font-medium text-slate-900 group-hover:text-indigo-600">
                    <a href="/components/home-services/servicedetail">
                      <span className="absolute -inset-2.5 z-10"></span>
                      <span className="relative">Service Details</span>
                    </a>
                  </h4>
                  <p className="relative mt-1.5 text-xs font-medium text-slate-500">
                    components
                  </p>
                </div>
              </li>
              <li>
                <div className="group relative before:absolute before:-inset-2.5 before:rounded-[20px] before:bg-gray-50 before:opacity-0 hover:before:opacity-100">
                  <div className="relative aspect-[2/1] overflow-hidden rounded-lg bg-gray-100 ring-1 ring-gray-900/10">
                    <img
                      src={logo}
                      alt=""
                      className="absolute inset-0 h-full w-full"
                    />
                  </div>
                  <h4 className="mt-4 text-sm font-medium text-slate-900 group-hover:text-indigo-600">
                    <a href="/components/home-services/full-screen-loader">
                      <span className="absolute -inset-2.5 z-10"></span>
                      <span className="relative">Full Screen Loaders</span>
                    </a>
                  </h4>
                  <p className="relative mt-1.5 text-xs font-medium text-slate-500">
                    components
                  </p>
                </div>
              </li>
              <li>
                <div className="group relative before:absolute before:-inset-2.5 before:rounded-[20px] before:bg-gray-50 before:opacity-0 hover:before:opacity-100">
                  <div className="relative aspect-[2/1] overflow-hidden rounded-lg bg-gray-100 ring-1 ring-gray-900/10">
                    <img
                      src={logo}
                      alt=""
                      className="absolute inset-0 h-full w-full"
                    />
                  </div>
                  <h4 className="mt-4 text-sm font-medium text-slate-900 group-hover:text-indigo-600">
                    <a href="/components/home-services/category-slider">
                      <span className="absolute -inset-2.5 z-10"></span>
                      <span className="relative">Category Slider</span>
                    </a>
                  </h4>
                  <p className="relative mt-1.5 text-xs font-medium text-slate-500">
                    components
                  </p>
                </div>
              </li>
            </ul>
          </section>
        </div>
      </section>
    </div>
  );
};

export default Section;
