import React, { useRef } from "react";
import {
  FaShoppingCart,
  FaShoePrints,
  FaTshirt,
  FaMobileAlt,
  FaBook,
  FaMusic,
  FaHome,
  FaGlasses,
  FaGamepad,
  FaGift,
  FaChevronLeft,
  FaChevronRight, 
} from "react-icons/fa";

const categories = [
  { id: 1, name: "Electronics", icon: <FaMobileAlt className="text-4xl" /> },
  { id: 2, name: "Clothing", icon: <FaTshirt className="text-4xl" /> },
  { id: 3, name: "Footwear", icon: <FaShoePrints className="text-4xl" /> },
  { id: 4, name: "Books", icon: <FaBook className="text-4xl" /> },
  { id: 5, name: "Music", icon: <FaMusic className="text-4xl" /> },
  { id: 6, name: "Home", icon: <FaHome className="text-4xl" /> },
  { id: 7, name: "Sunglasses", icon: <FaGlasses className="text-4xl" /> },
  { id: 8, name: "Gaming", icon: <FaGamepad className="text-4xl" /> },
  { id: 9, name: "Gifts", icon: <FaGift className="text-4xl" /> },
  {
    id: 10,
    name: "Shopping Cart",
    icon: <FaShoppingCart className="text-4xl" />,
  },
];

const CategorySlider = () => {
  const sliderRef = useRef(null);

  const scroll = (scrollOffset) => {
    if (sliderRef.current) {
      sliderRef.current.scrollLeft += scrollOffset;
    }
  };

  return (
    <div className="flex h-96 bg-gradient-to-r from-red-600 to-orange-600 overflow-hidden items-center justify-center relative">
      <button
        onClick={() => scroll(-250)}
        className="absolute top-1/2 md:left-60 sm:left-20 left-10 ml-2 transform -translate-y-1/2 rounded-full bg-gradient-to-r from-indigo-400 to-cyan-400 p-2 text-xl focus:outline-none z-40"
      >
        <FaChevronLeft className="text-white" />
      </button>
      <div
        ref={sliderRef}
        className="flex items-center overflow-hidden bg-gray-100 p-2 w-3/5 rounded-xl shadow-xl mx-auto relative z-30 overflow-x-auto scroll-smooth scrollbar-hide"
      >
        <div className="flex space-x-16">
          {categories.map((category) => (
            <div key={category.id} className="flex flex-col items-center mt-4">
              {category.icon}
              <p className="mt-4">{category.name}</p>
            </div>
          ))}
        </div>
      </div>
      <button
        onClick={() => scroll(250)}
        className="absolute top-1/2 md:right-60 sm:right-20 right-10 mr-2 transform -translate-y-1/2 rounded-full bg-gradient-to-r from-indigo-400 to-cyan-400 p-2 text-xl focus:outline-none z-40"
      >
        <FaChevronRight className="text-white" />
      </button>
    </div>
  );
};

export default CategorySlider;
