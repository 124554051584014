import React from "react";
import VideoBanner1 from "../../components/home-services/videoBanner/videoBanner1";
import ReactVideoBanner1 from "!!raw-loader!../../components/home-services/videoBanner/videoBanner1";
import PreviewCodeSection from "../../subcomponent/PreviewCode/PreviceCodeSection";

const ComponentData = [
  {
    id: 1,
    component: VideoBanner1,
    rawString_React: ReactVideoBanner1,
  },
];
const VideoBanners = () => {
  return (
    <>
      {ComponentData.map((obj) => {
        return (
          <PreviewCodeSection
            key={obj?.id}
            Component={obj?.component}
            rawString_React={obj?.rawString_React}
          />
        );
      })}
    </>
  );
};

export default VideoBanners;
