import logo from "./logo.svg";
import "./App.css";
import { Fragment, useState } from "react";
import { Dialog, Disclosure, Transition } from "@headlessui/react";
import Navbar from "./subcomponent/Navbar/navbar";
import Home from "./subContainer/Home/home";
import { BrowserRouter, Route, Routes, Link } from "react-router-dom";
import Address from "./subContainer/address/Address";
import Carts from "./subContainer/carts/Carts";
import FlyBtns from "./subContainer/fly-buttons/Flybtns";
import Questions from "./subContainer/questions/Questions";
import Card from "./subContainer/card/Card";
import Footers from "./subContainer/footers/Footers";
import Navbars from "./subContainer/navs/Navbars";
import Toasts from "./subContainer/toasts/Toasts";
import MyProfiles from "./subContainer/my-profiles/MyProfiles";
import SideBanner from "./subContainer/side-banners/SideBanner";
import HerosBanner from "./subContainer/heros-banner/HerosBanner";
import VideoBanners from "./subContainer/VideoBanners/VideoBanners";
import Services from "./subContainer/services-details/Services";
import ScreenLoaders from "./subContainer/screen-Loader/ScreenLoaders";
import Sliders from "./subContainer/category-sliders/Sliders";


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />

        <Route
          path="/components/social/sections/fly-button"
          element={<FlyBtns />}
        />
        <Route
          path="/components/social/sections/Questionaire"
          element={<Questions />}
        />

        <Route path="/components/social/sections/cards" element={<Card />} />
        <Route
          path="/components/home-services/add-address"
          element={<Address />}
        />
        <Route path="/components/home-services/carts" element={<Carts />} />
        <Route path="/components/home-services/footer" element={<Footers />} />
        <Route path="/components/home-services/nav" element={<Navbars />} />
        <Route path="/components/home-services/toast" element={<Toasts />} />
        <Route
          path="/components/home-services/my-profile"
          element={<MyProfiles />}
        />
        <Route
          path="/components/home-services/side-banner"
          element={<SideBanner />}
        />
        <Route
          path="/components/home-services/hero-banner"
          element={<HerosBanner />}
        />
        <Route
          path="/components/video-banners/video-banners"
          element={<VideoBanners />}
        />
        <Route
          path="/components/home-services/servicedetail"
          element={<Services />}
        />
        <Route
          path="/components/home-services/full-screen-loader"
          element={<ScreenLoaders />}
        />
        <Route
          path="/components/home-services/category-slider"
          element={<Sliders />}
        />
        {/* <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} /> */}
        <Route path="*" element={<h1>Page not found</h1>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
