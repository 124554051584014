import React, { useEffect, useState } from "react";

import HeroBanner1 from "../../components/home-services/hero-banner/HeroBanner1";
import HeroBanner2 from "../../components/home-services/hero-banner/HeroBanner2";
import HeroBanner3 from "../../components/home-services/hero-banner/HeroBanner3";
import HeroBanner4 from "../../components/home-services/hero-banner/HeroBanner4";
import HeroBanner5 from "../../components/home-services/hero-banner/HeroBanner5";

import ReactHeroBanner1 from "!!raw-loader!../../components/home-services/hero-banner/HeroBanner1";
import ReactHeroBanner2 from "!!raw-loader!../../components/home-services/hero-banner/HeroBanner2";
import ReactHeroBanner3 from "!!raw-loader!../../components/home-services/hero-banner/HeroBanner3";
import ReactHeroBanner4 from "!!raw-loader!../../components/home-services/hero-banner/HeroBanner4";
import ReactHeroBanner5 from "!!raw-loader!../../components/home-services/hero-banner/HeroBanner5";

import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import PreviewCodeSection from "../../subcomponent/PreviewCode/PreviceCodeSection";

import pretty from "pretty";

const ComponentData = [
  {
    id: 1,
    component: HeroBanner1,
    rawString_React: ReactHeroBanner1,
  },
  {
    id: 2,
    component: HeroBanner2,
    rawString_React: ReactHeroBanner2,
  },
  {
    id: 3,
    component: HeroBanner3,
    rawString_React: ReactHeroBanner3,
  },
  {
    id: 4,
    component: HeroBanner4,
    rawString_React: ReactHeroBanner4,
  },
  {
    id: 5,
    component: HeroBanner5,
    rawString_React: ReactHeroBanner5,
  },
];

const HerosBanner = () => {
  return (
    <>
      {ComponentData.map((obj) => {
        return (
          <PreviewCodeSection
            key={obj?.id}
            Component={obj?.component}
            rawString_React={obj?.rawString_React}
          />
        );
      })}
    </>
  );
};

export default HerosBanner;
