import React, { useEffect, useState } from "react";
import Navbar1 from "../../components/home-services/nav/Navbar1";
import Navbar2 from "../../components/home-services/nav/Navbar2";
import Navbar3 from "../../components/home-services/nav/Navbar3";
import Navbar4 from "../../components/home-services/nav/Navbar4";

import ReactNavbar1 from "!!raw-loader!../../components/home-services/nav/Navbar1";
import ReactNavbar2 from "!!raw-loader!../../components/home-services/nav/Navbar2";
import ReactNavbar3 from "!!raw-loader!../../components/home-services/nav/Navbar3";
import ReactNavbar4 from "!!raw-loader!../../components/home-services/nav/Navbar4";

import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import PreviewCodeSection from "../../subcomponent/PreviewCode/PreviceCodeSection";

import pretty from "pretty";
const ComponentData = [
  {
    id: 1,
    component: Navbar1,
    rawString_React: ReactNavbar1,
  },
  {
    id: 2,
    component: Navbar2,
    rawString_React: ReactNavbar2,
  },
  {
    id: 3,
    component: Navbar3,
    rawString_React: ReactNavbar3,
  },
  {
    id: 4,
    component: Navbar4,
    rawString_React: ReactNavbar4,
  },
];

const Navbars = () => {
  return (
    <>
      {ComponentData.map((obj) => {
        return (
          <PreviewCodeSection
            key={obj?.id}
            Component={obj?.component}
            rawString_React={obj?.rawString_React}
          />
        );
      })}
    </>
  );
};

export default Navbars;
