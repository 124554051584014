import React, { useEffect, useRef } from "react";

const FullScreenLoader7 = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-gray-300 bg-opacity-10">
      <div className="flex flex-col items-center justify-center h-screen w-screen z-50">
        <img
          src="https://oyelabs.com/wp-content/uploads/2023/01/Group-80-1.svg"
          alt="Logo"
          className="h-20 mb-4"
        />

        <div className="flex space-x-2 justify-center items-center bg-transparent mt-6">
          <span className="sr-only">Loading...</span>
          <div className="h-6 w-6 bg-blue-600 rounded-full animate-ping [animation-delay:-0.2s]"></div>
          <div className="h-6 w-6 bg-green-600 rounded-full animate-ping [animation-delay:-0.8s]"></div>
          <div className="h-6 w-6 bg-red-600 rounded-full animate-ping [animation-delay:-1s]"></div>
          <div className="h-6 w-6 bg-yellow-600 rounded-full animate-ping [animation-delay:-0.7s]"></div>
          <div className="h-6 w-6 bg-purple-600 rounded-full animate-ping [animation-delay:-0.4s]"></div>
          <div className="h-6 w-6 bg-orange-600 rounded-full animate-ping [animation-delay:-0.9s]"></div>
          <div className="h-6 w-6 bg-pink-600 rounded-full animate-ping"></div>
        </div>
      </div>
    </div>
  );
};

export default FullScreenLoader7;
