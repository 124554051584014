import React from "react";
import serviceImg from "../../social/assets/peakpx.jpg";
import { FaShieldAlt, FaStar, FaCheckCircle } from "react-icons/fa";
import ServiceDetails from "./ServiceDetails";

const Service1 = () => {
  return (
    <>
      <div
        style={{
          backgroundImage: `url(${serviceImg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundSize: "100%",
          position: "relative",
          height: "400px",
        }}
      >
        <div style={{ zIndex: 100 }} className="absolute px-80">
          <div className="flex gap-3 mt-10">
            <p className="text-white opacity-70">Home</p>
            <span className="text-blue-400">•</span>
            <p className="text-white opacity-70">Appliance Repair</p>
            <span className="text-blue-400">•</span>
            <p className="font-medium text-white">TV Services</p>
          </div>
          <div className="my-8 flex items-center gap-3">
            <h1 className="font-bold text-white" style={{ fontSize: "35px" }}>
              TV Service
            </h1>
            <FaShieldAlt className="text-white" />
          </div>
          <div className="py-1 px-3 bg-green-500 rounded flex items-center gap-2 w-fit mb-3">
            <FaStar className="text-amber-400" />
            <h1 className="font-bold text-white" style={{ fontSize: "20px" }}>
              4.78
            </h1>
            <p className="text-white">out of 5</p>
          </div>
          <p className="text-white" style={{ fontSize: "13px" }}>
            (5884 ratings on 5 services)
          </p>
          <div className="flex items-center gap-4 my-4">
            <FaCheckCircle className="text-white" />
            <p className="text-white">7 Days Service Warranty</p>
          </div>
          <div className="flex items-center gap-4 my-4">
            <FaCheckCircle className="text-white" />
            <p className="text-white">Trusted & Reliable Electricians</p>
          </div>
        </div>
        {/* Div for the black background */}
        <div
          className="absolute top-0 bg-black bg-opacity-60 w-full"
          style={{ zIndex: 1, height: "400px" }}
        ></div>
      </div>
      <ServiceDetails />
    </>
  );
};

export default Service1;
